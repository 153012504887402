
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
@Component({
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  // tab列表
  @Prop(Array) list!: any[];
  // tab选中id
  @Prop({ type: [String, Number], default: 0 }) value!: string | number;
  // tab标题字段自定义
  @Prop({ default: "title" }) title!: string;

  toCheckTab(el: any) {
    // v-model
    this.$emit("input", el.id);
    // 点击事件
    this.$emit("handleClick", el);
  }
}
