
import Vue from "vue";
import MydocMenu from "@/views/mydoc/menu.vue";
import MydocTabs from "@/views/mydoc/tabs.vue";
import MenuSimple from "@/views/mydoc/menuSimple.vue";
import FileList from "@/views/recycle/fileList.vue";
import TileList from "@/views/mydoc/tileList.vue";
import PreviewImg from "@/components/previewImg/index.vue";
import CooperationModal from "@/views/mydoc/CooperationModal.vue";
import ShareModal from "@/views/mydoc/ShareModal.vue";
import FileDetails from "@/components/FileDetails/index.vue";
import { Radio } from "ant-design-vue";
import Popup from "@/views/popup/index.vue";
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "@/views/mydoc/mixin/index";

export default Vue.extend({
  name: "SearchResult",
  mixins: [Mixins],
  components: {
    [Radio.name]: Radio,
    MydocTabs,
    MydocMenu,
    MenuSimple,
    FileList,
    TileList,
    Popup,
    PreviewImg,
    CooperationModal,
    ShareModal,
    FileDetails,
  },
  props: {},
  data() {
    return {
      tabList: [
        { id: 0, title: "我的文件收藏" },
        { id: 1, title: "团队文件收藏" },
      ],
      tabIndex: 0,
      columns: [
        {
          title: "名称",
          dataIndex: "userFileName",
          align: "left",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "修改时间",
          dataIndex: "updateTime",
          align: "center",
          scopedSlots: { customRender: "updateTime" },
        },
        // {
        //   title: "备注",
        //   dataIndex: "remark",
        //   align: "center",
        //   scopedSlots: { customRender: "comment" },
        // },
        {
          title: "文件大小",
          dataIndex: "userFileSize",
          align: "center",
          scopedSlots: { customRender: "size" },
        },
      ],
    };
  },
  watch: {
    tabIndex(val) {
      if (val === 1) {
        (this as any).moduleType = "team";
      } else {
        (this as any).moduleType = "doc";
      }
      this.fetchData();
    },
  },
  computed: {},
  created() {
    // 表示是个人邮箱企业
    if (this.$store.state.user.personalMailboxEnterprise) {
      this.tabList = [{ id: 0, title: "我的文件收藏" }];
    }
  },
  mounted() {},
  methods: {
    fetchData() {
      // @ts-ignore
      this.wrapLoading = true;
      if (this.tabIndex === 0) {
        return this.$apis
          .fileCollectionPageList({
            isPage: 0,
            moduleType: "doc",
            // companyId: 'person',
          })
          .then((res: any) => {
            console.log("收藏列表返回的数据res", res);

            // TODO  收藏接口返回字段变更，收藏与取消收藏操作用的是userFileId了
            // i.favoriteId=i.id
            // i.id=i.userFileId
            const list = res.data.list.map((i: any) => {
              i.userFileSize = i.fileSize ? i.fileSize : i.userFileSize;
              i.favoriteId=i.userFileId
              i.id=i.userFileId
              i.isCollected = 1;
              return i;
            });
            this.columns = (this as any).$options.data().columns;
            (this as any).setData({ list });
            return Promise.resolve();
          })
          .finally(() => {
            // @ts-ignore
            this.wrapLoading = false;
          });
      } else {
        return this.$apis
          .fileCollectionPageList({
            isPage: 0,
            moduleType: "team",
            // companyId: this.$store.state.user.companyId,
          })
          .then((res: any) => {
            console.log("团队收藏列表返回的数据res", res);
            // TODO  收藏接口返回字段变更，收藏与取消收藏操作用的是userFileId了
            const list = res.data.list.map((i: any) => {
              i.userFileSize = i.fileSize;
              i.favoriteId=i.userFileId
              i.id=i.userFileId
              i.isCollected = 1;
              return i;
            });
            this.columns = (this as any).$options.data().columns;
            this.columns.splice(1, 0, {
              title: "团队名称",
              dataIndex: "teamName",
              align: "center",
              scopedSlots: { customRender: "teamName" },
            });
            (this as any).setData({ list });
            return Promise.resolve();
          })
          .finally(() => {
            // @ts-ignore
            this.wrapLoading = false;
          });
      }
    },
    onTabChange() {},
  },
});
